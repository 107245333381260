import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatestWith, filter, map, Observable, take, tap } from 'rxjs';

import { errorPageRoute } from '../../../constants';
import { ConfigActions } from '../../../core/config/config.actions';
import { selectBhPropertiesURL, selectFlowType } from '../../../core/config/config.reducers';
import { CredentialsActions } from '../../../core/credentials/credentials.actions';
import { selectMfaUserEmailAndMobileState } from '../../../core/credentials/credentials.reducers';
import { LocalStorageService } from '../../../core/local-storage/local-storage.service';
import { SignupActions } from '../../../core/signup/signup.actions';
import { selectClientInfoState, selectPersonInfoState, selectSelectedEmployer } from '../../../core/signup/signup.reducers';
import {
  BhQueryParams,
  ContentPages,
  FlowType,
  IClientInfoState,
  IMFAUserEmailAndMobileState,
  IPersonInfoState,
  ISelectedEmployerState,
  LocalStorageKey,
} from '../../../models';
import { ContentActions } from 'src/app/core/content/content.actions';

// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class MfaGuard {
  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
  ) { }

  public canActivate(): true | Observable<boolean> {
    this.store.dispatch(ConfigActions.loading({ loading: true }));

    let flowType: FlowType = 'join';
    let hasUserToken: boolean = false;
    let otpenabled: boolean = false;

    this.store.select(selectSelectedEmployer)
      .pipe(
        take(1),
      )
      .subscribe((employerInfo: ISelectedEmployerState | null): void => {
        if (employerInfo) {
        } else {
        }
      });

    this.store.select(selectFlowType)
      .pipe(
        combineLatestWith(
          this.store.select(selectBhPropertiesURL),
        ),
        take(1),
      )
      .subscribe(([ flow, params ]: [FlowType, BhQueryParams]): void => {
        flowType = flow;
        const otpflowtype = (this.localStorageService.getDecryptedItem(LocalStorageKey.isOTPEnabled) || '').toString();
        const isLoginFlow = (this.localStorageService.getDecryptedItem(LocalStorageKey.isLoginFlow) || '').toString();
        if (otpflowtype === 'true') {
          otpenabled = true;
        }
        if (flowType === 'join') {
          // if the flow type is 'join', which is the default, check if we have the
          // user access token in the query params, because this will indicate to us
          // that we need to switch to the 'login' flow because the user was redirected
          // by the BE from CC login screen just for the MFA steps
          hasUserToken = Boolean(params.accesstoken);
          flowType = hasUserToken || otpenabled ? 'login' : 'join';

          if (hasUserToken || otpenabled) {
            const userInfoId: string = params.userinfoid ?? params.said ?? this.localStorageService.getItem(LocalStorageKey.userInfoId) as string;
            this.localStorageService.saveItem(LocalStorageKey.userInfoId, userInfoId);

            if(isLoginFlow === 'true')
            {
              this.store.dispatch(ConfigActions.loginType({ loginType: 'otp' }));   
              this.localStorageService.removeItem(LocalStorageKey.isLoginFlow);         
            }
            // if we have an access token in the query params, call the necessary endpoints and
            // update the state with the user access token and user info
            // eslint-disable-next-line max-len
            this.store.dispatch(SignupActions.getClientInfo({ input: params.clientguid ?? this.localStorageService.getItem(LocalStorageKey.clientGuid) as string }));

            //   this.store.dispatch(SignupActions.getBenefit());
            this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.standardLoginPage }));
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            this.store.dispatch(CredentialsActions.getMfaUserInfo({ input: userInfoId, isNonMfa: false }));
            this.store.dispatch(SignupActions.loadPersonInfo({ input: userInfoId }));
            if (hasUserToken && !otpenabled) {
              this.store.dispatch(ConfigActions.saveAppAuthToken({
                tokenInfo: {
                  userAccessToken: params.accesstoken,
                  userInfoId,
                },
              }));
            }
            this.store.dispatch(ConfigActions.flowType({ flowType }));
            // eslint-disable-next-line max-len
            this.localStorageService.saveItem(LocalStorageKey.clientGuid, params.clientguid ?? this.localStorageService.getItem(LocalStorageKey.clientGuid) as string);
          } else {
            //If user refreshes the page from MFA page then will get userInfoId
            const userInfoID = this.localStorageService.getItem(LocalStorageKey.userInfoId) as string;
            const userNavigationState = (this.localStorageService.getItem(LocalStorageKey.userNavigationState) || '').toString();
            const isPageRefresh = (this.localStorageService.getItem(LocalStorageKey.isPageRefresh) || '').toString();
            if(isLoginFlow === 'true')
            {
              this.store.dispatch(ConfigActions.loginType({ loginType: 'otp' }));   
              this.localStorageService.removeItem(LocalStorageKey.isLoginFlow);         
            }
            if(userInfoID && (isLoginFlow !== 'false') && (userNavigationState !== 'true' || isPageRefresh === 'true')) {
              flowType = 'login' ;             
              this.store.dispatch(ConfigActions.flowType({ flowType }));
              this.store.dispatch(SignupActions.getClientInfo({
                input: params.clientguid ?? this.localStorageService.getItem(LocalStorageKey.clientGuid) as string,
              }));
              this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.standardLoginPage }));
              this.store.dispatch(CredentialsActions.getMfaUserInfo({ input: userInfoID, isNonMfa: false }));
              this.store.dispatch(SignupActions.loadPersonInfo({ input: userInfoID }));    
              this.localStorageService.removeItem(LocalStorageKey.isPageRefresh);          
            }
            else{
            const clientGuidFromLocalStorage: string | null = this.localStorageService.getItem(LocalStorageKey.clientGuid);
            this.store.dispatch(SignupActions.getClientInfo({ input: clientGuidFromLocalStorage as string }));
            if(userInfoID)
            {
              this.store.dispatch(CredentialsActions.getMfaUserInfo({ input: userInfoID, isNonMfa: false }));
            }
            this.store.dispatch(SignupActions.eligibilityFromMagiclink());
            this.localStorageService.removeItem(LocalStorageKey.isLoginFlow);
            }
          }
        } else {
          // eslint-disable-next-line max-len
          this.store.dispatch(SignupActions.getClientInfo({ input: params.clientguid ?? this.localStorageService.getItem(LocalStorageKey.clientGuid) as string }));
          this.store.dispatch(SignupActions.clearMfaVerification());
          this.store.dispatch(SignupActions.clearSendotp());
          this.store.dispatch(SignupActions.clearVerifyOtp());
        }
        const isresentotp: string = (this.localStorageService.getDecryptedItem(LocalStorageKey.isresentotp) || '').toString();
        if (isresentotp === 'true' && !hasUserToken) {
          const userInfoID: string = (this.localStorageService.getItem(LocalStorageKey.userInfoId) || '').toString();
          this.store.dispatch(ContentActions.loadPageContentPattern({ page: ContentPages.loginMFAPage }));
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          this.store.dispatch(CredentialsActions.getMfaUserInfo({ input: userInfoID, isNonMfa: false }));
        }
      });

    // check if we have a use access token from the query params and if so, listen for the responses of the above
    // dispatched actions to mfa details, client info, and personal info endpoints. On success of all three, store
    // the necessary data in the state and continue, otherwise go to the error page
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (hasUserToken) {
      return this.store.select(selectMfaUserEmailAndMobileState)
        .pipe(
          combineLatestWith(
            this.store.select(selectClientInfoState),
            this.store.select(selectPersonInfoState),
          ),
          filter(([ mfaDetails, clientInfo, personInfo ]: [IMFAUserEmailAndMobileState, IClientInfoState, IPersonInfoState]): boolean =>
            (mfaDetails.loaded || mfaDetails.error !== null) &&
            (clientInfo.loaded || clientInfo.error !== null) &&
            (personInfo.loaded || personInfo.error !== null),
          ),
          take(1),
          tap(([ mfaDetails, clientInfo, personInfo ]: [IMFAUserEmailAndMobileState, IClientInfoState, IPersonInfoState]): void => {
            if (mfaDetails.loaded && clientInfo.loaded && personInfo.loaded) {
              this.store.dispatch(SignupActions.selectedEmployer({
                selectedEmployer: {
                  clientGuid: clientInfo.data?.crmClientId as string,
                  clientName: clientInfo.data?.clientName as string,
                  clientId: clientInfo.data?.clientId as number,
                } as ISelectedEmployerState,
              }));
              this.store.dispatch(SignupActions.employeeDetails({
                employeeDetails: {
                  clientGuid: clientInfo.data?.crmClientId as string,
                  clientName: clientInfo.data?.clientName as string,
                  clientId: clientInfo.data?.clientId as number,
                  userName: personInfo.data?.userName as string,
                },
              }));
            } else if (mfaDetails.error || clientInfo.error || personInfo.error) {
              this.router.navigate([ ...errorPageRoute ]);
            }
          }),
          map(([ mfaDetails, clientInfo, personInfo ]: [IMFAUserEmailAndMobileState, IClientInfoState, IPersonInfoState]): boolean =>
            mfaDetails.loaded && clientInfo.loaded && personInfo.loaded,
          ),
        );
    }

    return true;
  }
}

export const mfaGuard: CanActivateFn = (): true | Observable<boolean> => inject(MfaGuard).canActivate();
